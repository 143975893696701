// Single-product page template:

import React from "react"
import { Layout, ProductPage } from "../components"

const imageList = [
	{
		link:`/images/featured_defender_x.jpg`,
		alt:`Black jacket with an American flag on the sleeve and a white background.`,
	},
	{
		link:`/images/defender_x-back.jpg`,
		alt:`The back of a black jacket on a white background with the words 'Flight Crew' printed on the back.`,
	},
	{
		link:`/images/defender_x-two_tone.jpg`,
		alt:`A two-tone version of the jacket with red on top and black on bottom.`,
	},
]


export default function ProductDefenderX() {
	return (
		<Layout
			title="Defender X"
		>
			<ProductPage images={imageList}>
				<h2>Defender X Jacket</h2>
				<p>We offer a Nomex jacket to match you uniform: color, striping, and decoration. You can decide between three different weights of jackets, The basic light weight Nomex jackets or add a Nomex Fleece linear. We offer the full linear as permanent or a zip-out linear. Then you have the option of a zip-out Nomex fleece Vest. With all these options, you have a decision to make.</p>

				<h3>Features</h3>
				<ul>
					<li>Angled chest pockets with two entry points for storage and warmth</li>
					<li>Sleeve pocket</li>
					<li>2” x 4” velcro for name tag</li>
					<li>Knit cuffs and waistband</li>
				</ul>
			</ProductPage>

		</Layout>
	)
}
